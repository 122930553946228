
import {defineComponent, reactive, toRefs,ref, computed} from 'vue'
import { toThousands } from "@/utils/approve";
import { toThousandsAndTwoDecimal } from '@/utils/payment/numberFormat'
import useCreatePayoutCode from "@/hooks/payment/bu/useCreatePayoutCode";
import {PayoutBatchTableData} from "@/views/Payment/BU/types";
import {elementSize} from "@/utils";
import {ColumnProps} from "ant-design-vue/es/table/interface";
import SelectProgramConfirm from "@/views/Payment/components/selectProgramConfirm.vue";
import {getSearchValue} from "@/API/approve";
import {message, Modal} from "ant-design-vue";
import {useRoute,useRouter} from "vue-router";
import  {useStore} from "vuex"
import ApproveSteps from "@/views/Payment/components/ApproveSteps.vue";
import usePageSpinning from '@/utils/usePageSpinning'
import Decimal from '@/utils/closing/decimal'
type Key = ColumnProps['key'];
export default defineComponent({
  name: 'CreatePayoutBatch',
  props: {},
  components: {
    SelectProgramConfirm,
    ApproveSteps
  },
  setup() {
    const route = useRoute()
    const {state} = useStore()
    const { commit } = useStore()
    const businessType = route.query.type
    console.log('路由========',route.query.paymentOverviewId)
    const { states,columnsPayoutBatch, tableDataPayoutBatch, tableDataPendingCreateBatch, doSearch,getCreatePayoutBatchDetail,addPayoutBatch,visibleSelectProgram,selectedProgramList,isSavedFlag,isSubmittedFlag,isAddFlag,isRemoveFlag,createPayoutSubmitButton,createPayoutSaveButton, approveLines, approveLinesRes, getApproveLines,
      approveVisible, approveClick, approveList } = useCreatePayoutCode()
    states.paymentOverviewId = route.query.paymentOverviewId as string
    const rowSelectionPayoutCode = reactive<{ selectedRowKeys: Key[]; selectedRowData: PayoutBatchTableData[]; onChange: Function;columnWidth: string}>({
      onChange: (selectedRowKeys: Key[], selectedRows: PayoutBatchTableData[]) => {
        rowSelectionPayoutCode.selectedRowKeys = selectedRowKeys;
        rowSelectionPayoutCode.selectedRowData = selectedRows;
      },
      selectedRowKeys: [],
      selectedRowData: [],
      columnWidth: '30px'
    })
    const sumPayoutAmountWVat = ref<number>(0)
    const sumPayoutAmountWoVat = ref<number>(0)
    const pageSpinning = usePageSpinning()
    if (businessType == 'edit'){
        pageSpinning(getCreatePayoutBatchDetail(route.query.paymentOverviewId as string))
    }
    if (businessType == 'add'){
      console.log('进入add模式=======')
      console.log('预打包的program行数据======',state.payment.selectProgramRows)
      const myprogramList = state.payment.selectProgramRows as PayoutBatchTableData[]
      myprogramList.forEach((item: any)=>{
        sumPayoutAmountWVat.value= sumPayoutAmountWVat.value+item.payoutAmountWVat
        sumPayoutAmountWoVat.value= sumPayoutAmountWoVat.value+item.payoutAmountWOVat
      })
      getApproveLines(myprogramList).then((res: any) => {
        states.approveline = res[0]
      })
      states.programNo = state.payment.selectProgramRows.length
      states.payoutRound = state.payment.selectProgramRows[0]?state.payment.selectProgramRows[0].payoutRound:''
      states.payoutStatus = ''
      states.payoutAmoutwVat = toThousandsAndTwoDecimal(sumPayoutAmountWVat.value)
      states.payoutAmoutwoVat = toThousandsAndTwoDecimal(sumPayoutAmountWoVat.value)
      states.payoutCode = ''
      states.submitDate = ''
      states.paymentOverviewId = ''
      tableDataPayoutBatch.value = state.payment.selectProgramRows
      states.detailIds = []//清空编辑页面的数据
      //为Add按钮准备参数
      tableDataPayoutBatch.value?.forEach((item,index,array)=>{
        (states.detailIds as any).push(item.id)
      })
      route.query.paymentOverviewId = ''
    }
    //#region [selectProgram列表页确认按钮功能]
    const handlerSelectProgramConfirm = (selectedRows: []): void => {
      let amountWVatSum = new Decimal(0)
      let amountWoVatSum = new Decimal(0)
      const newSelectProgramlist = []
      selectedRows.forEach((item: any)=>{
        const obj = Object.assign({})
        obj.id = item.id
        obj.programBu = item.programBu
        obj.payoutRound = item.payoutRound
        obj.programCode = item.programCode
        obj.payoutAmountWVat = parseFloat(item.payoutAmountWVat)
        obj.payoutAmountWOVat = parseFloat(item.payoutAmountWOVat)
        obj.programName = item.programName
        obj.dealerNumber = item.dealerNumber
        state.payment.selectProgramRows.push(obj)
      })
      states.detailIds = []//清空编辑页面的数据
      state.payment.selectProgramRows.forEach((item: any)=>{
        (states.detailIds as any).push(item.id)
        amountWVatSum = amountWVatSum.plus(item.payoutAmountWVat)
        amountWoVatSum = amountWoVatSum.plus(item.payoutAmountWOVat)
      })
      states.payoutAmoutwVat = amountWVatSum.round(2)
      states.payoutAmoutwoVat = amountWoVatSum.round(2)
      states.programNo =  state.payment.selectProgramRows.length
      getApproveLines(state.payment.selectProgramRows)
    }
    //#endregion
    //#region [remove 按钮功能]
    const removePayoutBatch = () => {
      if ((!rowSelectionPayoutCode.selectedRowData || rowSelectionPayoutCode.selectedRowData.length === 0)) {
        message.warning('Please select the data first');
        return;
      }
      rowSelectionPayoutCode.selectedRowData.forEach((itemChild: any)=> {
        const index = state.payment.selectProgramRows.findIndex((item: any) => {
          return item.id == itemChild.id
        })
        if (index >= 0) {
          state.payment.selectProgramRows.splice(index, 1)
        }
      })
      states.detailIds = []
      let amountWVatSum = new Decimal(0)
      let amountWoVatSum = new Decimal(0)
      state.payment.selectProgramRows.forEach((item: any)=>{
        (states.detailIds as any).push(item.id)
        amountWVatSum = amountWVatSum.plus(item.payoutAmountWVat)
        amountWoVatSum = amountWoVatSum.plus(item.payoutAmountWOVat)
      })
      states.payoutAmoutwVat = amountWVatSum.round(2)
      states.payoutAmoutwoVat = amountWoVatSum.round(2)
      states.programNo =  state.payment.selectProgramRows.length
      getApproveLines(state.payment.selectProgramRows)
    }
    //#endregion
    const onSubmit = ()=>{
      if (tableDataPayoutBatch.value?.length == 0){
        message.error('there is no programs,can not save and submit!')
        return;
      }
      Modal.confirm({
            title: 'Tips',
            content: 'will be save and submit',
            onOk() {
              createPayoutSubmitButton()
            }
          });
     
    }
    const onSave = ()=>{
      if (tableDataPayoutBatch.value?.length == 0){
        message.error('there is no programs,can not save and submit!')
        return;
      }
      createPayoutSaveButton()
    }
    // const approveLines = computed(() => {
    //   if (!tableDataPayoutBatch.value) {
    //     return []
    //   }
    //   return Array.from(tableDataPayoutBatch.value.reduce((r, item) => r.add(item.programBu), new Set()))
    // })
    const tableSize = elementSize('.ant-table-wrapper')
    return {
      columnsPayoutBatch,
      ...toRefs(states),
      rowSelectionPayoutCode,
      visibleSelectProgram,
      isSavedFlag,
      isSubmittedFlag,
      isAddFlag,
      isRemoveFlag,
      selectedProgramList,
      getCreatePayoutBatchDetail,
      addPayoutBatch,
      tableDataPayoutBatch,
      handlerSelectProgramConfirm,
      removePayoutBatch,
      onSubmit,
      onSave,
      createPayoutSubmitButton,
      createPayoutSaveButton,
      sumPayoutAmountWVat,
      sumPayoutAmountWoVat,
      toThousands,
      toThousandsAndTwoDecimal,
      tableSize,
      approveLines,
      approveVisible,
      approveClick,
      approveList
      
    }
  }
})
